<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <v-container fill-height fluid align="top">
          <viewer ref="viewer" v-if="image" @inited="initViewer" :images="[image]" :options="viewerOptions">
            <img style="display: none" :src="image" :alt="file.name">
          </viewer>
        </v-container>
      </v-col>
      <v-col cols="4">
        <v-file-input
          v-model="file"
          single-line
          solo
          flat
          prepend-icon=""
          hide-details
          :loading="isLoading"
          :disabled="isLoading"
          placeholder="Upload CN22 image"
          class="mb-5"
        ></v-file-input>
        <v-list dense rounded v-if="cn22">
          <v-list-item v-for="(field,key) in cn22.data" :key="key">
            <v-list-item-content>
              <v-list-item-subtitle class="overline" :style="`background-color: ${colors[key]}`">
                {{ key }} ({{ field ? (Math.ceil((field.confidence || 0) * 100)).toFixed(0) : 0 }}%)
              </v-list-item-subtitle>
              <v-list-item-title> {{ field ? field.text : 'N/A' }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-dialog v-model="rawDataDialog">
      <v-card>
        <json-viewer
          :value="rawData"
          :expand-depth="3"
          :copyable="{copyText: 'COPY', copiedText: 'Copied to clipboard' }"
          sort
          :theme="`${$vuetify.theme.dark ? 'json-viewer-dark-theme' : 'json-viewer-light-theme'}`"
        ></json-viewer>
        <v-card-actions>
          <v-btn @click="rawDataDialog = false" block rounded outlined text>close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import _debounce from 'lodash.debounce'
import JsonViewer from 'vue-json-viewer'
import Vue from 'vue'

Vue.use(JsonViewer)

export default {
  data: () => ({
    cn22: null,
    video: null,
    canvas: {},
    context: null,
    file: null,
    image: null,
    bboxImage: null,
    scanDialog: false,
    isLoading: false,
    rawDataDialog: false,
    rawData: null,
    colors: {},
    viewerOptions: {
      inline: true,
      button: true,
      navbar: false,
      title: [4, (image, imageData) => `${image.alt} (${imageData.naturalWidth} × ${imageData.naturalHeight})`],
      toolbar: {
        zoomIn: 4,
        zoomOut: 4,
        oneToOne: 4,
        reset: 4,
        rotateLeft: 4,
        rotateRight: 4,
        flipHorizontal: 4,
        flipVertical: 4,
      },
      loading: false,
      minWidth: 640,
      minHeight: (window.innerHeight * 0.95).toFixed(0),
      tooltip: true,
      movable: true,
      zoomable: true,
      rotatable: true,
      scalable: true,
      transition: false,
      fullscreen: false,
      keyboard: true,
      url: "src",
    },
    isFetchingDevices: false
  }),
  watch: {
    file: {
      handler(file) {
        let fileReader = new FileReader()

        fileReader.onloadend = async () => {
          this.isLoading = this.viewerOptions.loading = true
          this.image = fileReader.result

          this.canvas = document.createElement('canvas')
          this.context = this.canvas.getContext('2d')

          let image = new Image()

          image.onload = () => {
            this.canvas.height = image.height
            this.canvas.width = image.width

            this.context.drawImage(image, 0, 0, image.width, image.height)
          }
          image.src = this.image

          let formData = new FormData()
          formData.append('image', file, file.name)
          formData.append('created_by', '1')

          this.$server.post(this.$store.state.manifestServerAddress + '/v1/cn22/', {body: formData}).json().then(resp => {
            this.cn22 = resp

            for (const field in this.cn22.data) {
              if (this.cn22.data[field] === null) continue
              let bbox = this.cn22.data[field].boundingBox
              const color = 360 * Math.random()
              this.colors[field] = 'hsl(' + color + ', 70%, 50%, 0.6)'
              this.drawBBox(bbox, this.colors[field], null,'hsl(' + color + ', 50%, 50%, 0.2)')
            }

            this.image = this.bboxImage = this.canvas.toDataURL('image/jpeg')
            this.isLoading = false
            this.viewerOptions.loading = false
          }).catch(() => {
            this.viewerOptions.loading = false
            this.isLoading = false

          })

          // setTimeout(() => {
          //   this.isLoading = this.viewerOptions.loading = false
          //   this.cn22 = {
          //     "id": 104,
          //     "image": "https://skunkworksai.blob.core.windows.net/projectsali/cn22/bg/bg_cn22-004_20200325101705900384.jpg?se=2020-03-25T11%3A17%3A09Z&sp=r&sv=2019-02-02&sr=b&sig=SPJYHhvtWJCqaNx5houogELrI/JUnid2spEzBmyEDWM%3D",
          //     "created_by": 1,
          //     "data": {
          //       "email": {
          //         "type": "string",
          //         "valueString": "tyreckwaters@yahoo.com",
          //         "text": "tyreckwaters@yahoo.com",
          //         "page": 1,
          //         "boundingBox": [702, 355, 1082, 355, 1082, 388, 702, 388],
          //         "confidence": 0.878,
          //         "elements": ["#/analyzeResult/readResults/0/lines/17/words/0"]
          //       },
          //       "date": {
          //         "type": "string",
          //         "valueString": "21 / 11 / 2020",
          //         "text": "21 / 11 / 2020",
          //         "page": 1,
          //         "boundingBox": [1279, 614, 1500, 614, 1500, 645, 1279, 645],
          //         "confidence": 0.999,
          //         "elements": ["#/analyzeResult/readResults/0/lines/53/words/0", "#/analyzeResult/readResults/0/lines/53/words/1", "#/analyzeResult/readResults/0/lines/53/words/2", "#/analyzeResult/readResults/0/lines/53/words/3", "#/analyzeResult/readResults/0/lines/53/words/4"]
          //       },
          //       "description": {
          //         "type": "string",
          //         "valueString": "mousepad",
          //         "text": "mousepad",
          //         "page": 1,
          //         "boundingBox": [92, 300, 251, 300, 251, 341, 92, 341],
          //         "confidence": 1,
          //         "elements": ["#/analyzeResult/readResults/0/lines/13/words/0"]
          //       },
          //       "bar_code": {
          //         "type": "string",
          //         "valueString": "LC661294790AU LC661294790AU",
          //         "text": "LC661294790AU LC661294790AU",
          //         "page": 1,
          //         "boundingBox": [226, 221, 1250, 221, 1250, 253, 226, 253],
          //         "confidence": 0.998,
          //         "elements": ["#/analyzeResult/readResults/0/lines/8/words/0", "#/analyzeResult/readResults/0/lines/9/words/0"]
          //       },
          //       "address": {
          //         "type": "string",
          //         "valueString": "9 BRUNTS ROAD",
          //         "text": "9 BRUNTS ROAD",
          //         "page": 1,
          //         "boundingBox": [704, 422, 963, 422, 963, 457, 704, 457],
          //         "confidence": 1,
          //         "elements": ["#/analyzeResult/readResults/0/lines/22/words/0", "#/analyzeResult/readResults/0/lines/22/words/1", "#/analyzeResult/readResults/0/lines/22/words/2"]
          //       },
          //       "address_suburb": {
          //         "type": "string",
          //         "valueString": "Suburb BOAT HARBOUR",
          //         "text": "Suburb BOAT HARBOUR",
          //         "page": 1,
          //         "boundingBox": [696, 501, 1011, 501, 1011, 532, 696, 532],
          //         "confidence": 0.953,
          //         "elements": ["#/analyzeResult/readResults/0/lines/38/words/0", "#/analyzeResult/readResults/0/lines/38/words/1", "#/analyzeResult/readResults/0/lines/38/words/2"]
          //       },
          //       "sender_name": {
          //         "type": "string",
          //         "valueString": "Tyreek waters",
          //         "text": "Tyreek waters",
          //         "page": 1,
          //         "boundingBox": [700, 283, 918, 283, 918, 315, 700, 315],
          //         "confidence": 0.978,
          //         "elements": ["#/analyzeResult/readResults/0/lines/12/words/0", "#/analyzeResult/readResults/0/lines/12/words/1"]
          //       },
          //       "contact_no": {
          //         "type": "string",
          //         "valueString": "103 49 87 6432",
          //         "text": "103 49 87 6432",
          //         "page": 1,
          //         "boundingBox": [1274, 355, 1549, 355, 1549, 390, 1274, 390],
          //         "confidence": 0.999,
          //         "elements": ["#/analyzeResult/readResults/0/lines/18/words/0", "#/analyzeResult/readResults/0/lines/18/words/1", "#/analyzeResult/readResults/0/lines/18/words/2", "#/analyzeResult/readResults/0/lines/18/words/3"]
          //       },
          //       "value": {
          //         "type": "string",
          //         "valueString": "78.00",
          //         "text": "78.00",
          //         "page": 1,
          //         "boundingBox": [147, 562, 262, 562, 262, 592, 147, 592],
          //         "confidence": 1,
          //         "elements": ["#/analyzeResult/readResults/0/lines/46/words/0"]
          //       },
          //       "address_postcode": {
          //         "type": "string",
          //         "valueString": "7321",
          //         "text": "7321",
          //         "page": 1,
          //         "boundingBox": [1131, 539, 1198, 539, 1198, 569, 1131, 569],
          //         "confidence": 0.997,
          //         "elements": ["#/analyzeResult/readResults/0/lines/44/words/1"]
          //       },
          //       "person_lodging": {
          //         "type": "string",
          //         "valueString": "Richelle Clough",
          //         "text": "Richelle Clough",
          //         "page": 1,
          //         "boundingBox": [732, 610, 964, 610, 964, 649, 732, 649],
          //         "confidence": 0.956,
          //         "elements": ["#/analyzeResult/readResults/0/lines/52/words/0", "#/analyzeResult/readResults/0/lines/52/words/1"]
          //       },
          //       "address_state": {
          //         "type": "string",
          //         "valueString": "TAS",
          //         "text": "TAS",
          //         "page": 1,
          //         "boundingBox": [774, 539, 828, 539, 828, 571, 774, 571],
          //         "confidence": 0.952,
          //         "elements": ["#/analyzeResult/readResults/0/lines/43/words/0"]
          //       },
          //       "tariff_no": {
          //         "type": "string",
          //         "valueString": "35431000",
          //         "text": "35431000",
          //         "page": 1,
          //         "boundingBox": [79, 401, 239, 401, 239, 435, 79, 435],
          //         "confidence": 1,
          //         "elements": ["#/analyzeResult/readResults/0/lines/20/words/0"]
          //       },
          //       "net_weight": {
          //         "type": "string",
          //         "valueString": "1-29",
          //         "text": "1-29",
          //         "page": 1,
          //         "boundingBox": [433, 563, 503, 563, 503, 596, 433, 596],
          //         "confidence": 0.999,
          //         "elements": ["#/analyzeResult/readResults/0/lines/47/words/0"]
          //       },
          //       "reason_for_export": null
          //     },
          //     "scan_time": 36.1740996837616,
          //     "date_created": "2020-03-25T10:17:07.838594Z",
          //     "date_modified": "2020-03-25T10:17:07.838633Z"
          //   }
          //   for (const field in this.cn22.data) {
          //     if (this.cn22.data[field] === null) continue
          //     let bbox = this.cn22.data[field].boundingBox
          //     const color = 360 * Math.random()
          //     this.colors[field] = 'hsl(' + color + ', 70%, 50%, 0.6)'
          //     this.drawBBox(bbox, this.colors[field], null,'hsl(' + color + ', 50%, 50%, 0.2)')
          //   }
          //   this.context.save()
          //   this.image = this.bboxImage = this.canvas.toDataURL('image/jpeg')
          // }, 1000)
        }

        if (file) fileReader.readAsDataURL(file)
      },
      deep: true
    }
  },
  computed: {
    deviceName: {
      set: _debounce(function (val) {
        this.$store.commit('setDeviceName', val)
      }, 500),
      get() {
        return this.$store.state.device.name
      }
    }
  },
  methods: {
    viewRawData(rawData) {
      this.rawData = this.$store.state.parcel[`${rawData}Raw`]
      this.rawDataDialog = true
    },
    initViewer(viewer) {
      this.$viewer = viewer
    },
    drawBBox(bbox, strokeStyle, lineWidth, fillStyle) {
      this.context.beginPath()
      this.context.moveTo(bbox[0], bbox[1])
      this.context.lineTo(bbox[2], bbox[3])
      this.context.lineTo(bbox[4], bbox[5])
      this.context.lineTo(bbox[6], bbox[7])
      this.context.closePath()
      this.context.lineWidth = lineWidth || 2
      this.context.strokeStyle = strokeStyle
      if (fillStyle) {
        this.context.fillStyle = fillStyle
        this.context.fill()
      }
      this.context.stroke()
    },
    hover(bbox) {
      if (bbox == null) return
      this.drawBBox(bbox, 'red', 4)
      this.image = this.canvas.toDataURL('image/jpeg')
    },
    reset() {
      this.image = this.bboxImage
    }
  },
  beforeMount() {
    // Fetch devices from server
    this.$server.get(this.$store.state.manifestServerAddress + '/v1/devices/').json().then(response => {
      if ('count' in response && response.count)
        this.$store.commit('setState', ['devices', response.results])
      this.isFetchingDevices = false
    }).catch(() => {
      this.isFetchingDevices = false
    })
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>

  .viewer {
    height: 100% !important;
    display: inline-block !important;

    .images {
      height: 100% !important;
      display: none;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 5px;

      .image-wrapper {
        display: inline-block;
        width: calc(33% - 20px);
        margin: 5px 5px 0 5px;

        .image {
          width: 100%;
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }
</style>
